<template>
  <div>
    <slot name="title">
      <h3 class="white--text text-center mb-5">Welcome to your Sony Rebate & SPIFF Center</h3>
    </slot>
    <v-form v-if="!validCredentials" v-model="valid" @submit.stop.prevent="onSubmit">
      <v-card class="elevation-12">
        <v-toolbar dark color="info">
          <v-toolbar-title>
            <span>Login</span>
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <ApiError :errors="errors" :message="errorMessage"></ApiError>
          <v-container>
            <v-row no-gutters>
              <v-col cols="12">
                <v-text-field
                  v-model="form.username"
                  :rules="rules.username"
                  label="Username or Email"
                  name="email"
                  prepend-icon="mdi-account"
                  type="text"
                  required
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="12">
                <v-text-field
                  v-model="form.password"
                  label="Password"
                  name="password"
                  hint="Passwords are case-sensitive"
                  prepend-icon="mdi-lock"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showPassword = !showPassword"
                  :type="showPassword ? 'text' : 'password'"
                >
                </v-text-field>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="12" align="right">
                <router-link :to="{ name: 'forgot-password' }">Forgot Password</router-link>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-btn block type="submit" color="primary" :loading="loading" :disabled="loading || !isValidUsername"
                  >Login</v-btn
                >
              </v-col>
            </v-row>

            <v-row>
              <slot name="contact_information">
                <v-col cols="6" align="center" v-if="selectedProgram && selectedProgram.programEmail">
                  Help? <a :href="'mailto:' + selectedProgram.programEmail">{{ selectedProgram.programEmail }}</a>
                </v-col>
                <v-col cols="6" align="center" v-if="selectedProgram && selectedProgram.programPhone">
                  Call? {{ selectedProgram.programPhone }}
                </v-col>
              </slot>
            </v-row>

            <v-row>
              <v-col cols="12" align="center">
                <slot name="login_content">
                  <p>
                    Don't have sales contract with Sony? Click below to complete a quick registration form to determine
                    your locations eligibility.
                  </p>
                </slot>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="12">
                <slot name="register">
                  <v-btn block type="button" color="primary" :to="{ name: 'register' }">Register</v-btn>
                </slot>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-form>
    <v-card v-else-if="!loading && participants && participants.length > 1" class="elevation-12">
      <v-toolbar dark color="info">
        <v-toolbar-title>
          <span> Select a program you are enrolled in ...</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on: on }">
            <v-btn icon v-on="on" @click="onClear()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
          <span>{{ $i18n.translate("Cancel") }}</span>
        </v-tooltip>
      </v-toolbar>
      <v-card-text>
        <v-list two-line subheader>
          <v-list-item v-for="participant in participants" :key="participant.id" @click="onSelect(participant)">
            <v-list-item-content>
              <v-list-item-title>
                {{ participant.program.name }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ participant.fullName }} as {{ participant.participantType.name }}
                <span v-if="participant.organization"> of {{ participant.organization.name }} </span>
                - {{ participant.participantKey }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon color="grey lighten-1">mdi-chevron-right</v-icon>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
    <v-card v-else-if="participants && participants.length == 0" class="elevation-12">
      <v-toolbar dark color="info">
        <v-toolbar-title>
          <span> You aren't enrolled in any program. </span>
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-alert dense outlined type="error">
          If you think this is an error simply call {{ env_phone }}. Our customer care team is available between 8:30am
          and 5:00pm (ct) Monday through Friday to assist.
        </v-alert>
      </v-card-text>
    </v-card>
    <v-card v-else class="elevation-12">
      <v-toolbar dark color="info">
        <v-toolbar-title>
          <span>Redirecting to your program ...</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on: on }">
            <v-btn icon v-on="on" @click="onClear()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
          <span>{{ $i18n.translate("Cancel") }}</span>
        </v-tooltip>
      </v-toolbar>
      <v-card-text>
        Redirecting to your program. If you are not redirected shortly, please cancel and retry login.
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ApiError from "../../gapp-components/components/display/ApiError";

export default {
  name: "Login",
  metaInfo: {
    title: "Login"
  },
  components: {
    ApiError
  },
  data() {
    return {
      errors: {},
      errorMessage: null,
      valid: false,
      loading: false,
      validCredentials: false,
      form: {},
      showPassword: false,
      rules: {
        username: [v => !!v || "Username or Email is required"]
      },
      participants: [],
      unauthorizedError: false,
      program: null
    };
  },
  methods: {
    onSelect(participant) {
      return this.$auth
        .storeSelectParticipant(participant)
        .then(() => {
          this.$router.push({ name: "dashboard" }).catch(error => {
            console.log(error);
          });
        })
        .catch(error => {
          console.log(error);
        });
    },
    onClear() {
      this.participants = [];
      this.form.password = "";
      this.validCredentials = false;
    },
    onSubmit() {
      // if invalid, prevent submission
      if (!this.valid) {
        return;
      }

      // reset errors
      this.errors = {};

      // Set loading spinner
      this.loading = true;

      // clear existing errors
      this.$auth.logout();

      // submit login request
      this.$auth
        .login(this.form.username, this.form.password)
        .then(() => {
          // determine if there are multiple programs for this user
          return this.$auth.storeAvailableParticipants();
        })
        .then(() => {
          this.participants = this.availableParticipants;
          this.validCredentials = true;
          if (this.participants && this.participants.length == 1) {
            return this.onSelect(this.participants[0]);
          } else if (this.participants && this.participants.length > 1) {
            this.loading = false;
          } else {
            this.loading = false;
            return Promise.reject("No participants found");
          }
        })
        .catch(e => {
          this.loading = false;
          this.errors = this.$api.getErrorsFromResponse(e);
          if (this.errors.isUnauthorized(e)) {
            this.errorMessage = "Invalid username or password";
          } else if (this.errors.isCredentialsExpired(e)) {
            this.errorMessage = "Your credentials have expired, redirecting ...";
            setTimeout(() => {
              let key = this.$api.getCredentialsExpirationKey(e);
              return this.$router.push({ name: "credentials-expired", query: { key: key } });
            }, 1000);
          }
          this.onClear();
        });
    }
  },
  computed: {
    ...mapGetters(["availableParticipants", "selectedProgram"]),
    isValidUsername() {
      return this.form && this.form.username;
    }
  }
};
</script>
